export const titleObj = {
  languageTitle: "Language",
  spaceNetwork: "The Installed Network<span class='title-color'>Can Make Money</span>",
  spaceNetworkSubTitle: "Let's help you maximize the benefits of idle networks.",
  cleanTitle: "much <span class='title-color'>cleaner</span>",
  cleanSubTitle: "WE ARE THE ONLY",
  industryTitle: "Industry",
  industrySubTitle: "We have deep cooperation with various industries to achieve win-win results.",
  partnersTitle: "Partners",
  partnersSubTitle: "",
  requestTitle: "Request",
  requestSubTitle: "Machine Configuration",
  scriptTitle: "Script",
  scriptSubTitle: "Download and use scripts.",
  docTitle: "Ubuntu22.04 LTS Installation Tutorial",

  nodeName: {
    // 地图节点名称

    Malaysia: "Malaysia node",
    Thailand: "Thailand node",
    Indonesian: "Indonesian node",
    Philippine: "Philippine node",
    Vietnam: "Vietnam node",
    Japan: "Cambodia node",
  },
};
