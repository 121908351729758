export function windowWidth() {
  let w = window.screen.width;
  window.addEventListener("resize", () => {
    w = window.screen.width || 1920;
  });
  //   console.log("w---", w);
  return w;
}

export function screenWidth() {
  initScreenWidth();
  window.addEventListener("resize", () => {
    initScreenWidth();
  });
}

function initScreenWidth() {
  let baseSize = 56;
  const cw = document.body.clientWidth;
  if (cw >= 768) {
    baseSize = cw / 25;
    if (cw >= 1334) {
      baseSize = 56;
    }
  } else {
    baseSize = cw / 15;
  }
  document.documentElement.style.fontSize = baseSize + "px";
}
