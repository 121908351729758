export const titleObj = {
  languageTitle: "語言",
  spaceNetwork: "空閒網絡<span class='title-color'>賺取收益</span>",
  spaceNetworkSubTitle: "Let's help you maximize the benefits of idle networks.",
  cleanTitle: "真正的<span class='title-color'>乾淨</span>",
  cleanSubTitle: "WE ARE THE ONLY",
  industryTitle: "行業",
  industrySubTitle: "我們與各行業深度合作，實現共贏",
  partnersTitle: "同伴",
  partnersSubTitle: "",
  requestTitle: "要求",
  requestSubTitle: "機器網絡配置要求",
  scriptTitle: "腳本",
  scriptSubTitle: "下載腳本，開始使用",
  docTitle: "Ubuntu22.04 LTS 装机教程",

  nodeName: {
    // 地图节点名称
    Malaysia: "马来西亚节点",
    Thailand: "泰国节点",
    Indonesian: "印尼节点",
    Philippine: "菲律宾节点",
    Vietnam: "越南节点",
    Japan: "日本节点",
  },
};
