import Vue from "vue";
import VueRouter from "vue-router";
import { windowWidth } from "@/utils/index";

Vue.use(VueRouter);
const screenW = windowWidth();
// console.log("windowWidth************", screenW);
const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "@/layout/index.vue"),
    name: "layout",
    meta: {
      title: "layout ",
    },
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
        meta: {
          title: " 首页 ",
        },
      },
      {
        path: "/script",
        name: "script",
        component: () => import(/* webpackChunkName: "solution" */ "@/views/script/index.vue"),
        meta: {
          title: " 脚本 ",
        },
      },
      {
        path: "/document",
        name: "document",
        component: () => import(/* webpackChunkName: "document" */ "@/views/document/index.vue"),
        meta: {
          title: " 文档 ",
        },
      },
      // {
      //   path: "/solution",
      //   name: "Solution",
      //   component: () => import(/* webpackChunkName: "solution" */ "@/views/solution/index.vue"),
      //   meta: {
      //     title: " 解决方案 ",
      //   },
      // },
      // {
      //   path: "/understandICDN",
      //   name: "UnderstandICDN",
      //   component: () => import(/* webpackChunkName: "understandICDN" */ "@/views/understandICDN/index.vue"),
      //   meta: {
      //     title: " 了解ICDN ",
      //   },
      // },
      // {
      //   path: "/document",
      //   name: "Document",
      //   component: () => import(/* webpackChunkName: "document" */ "@/views/document/index.vue"),
      //   meta: {
      //     title: " 文档 ",
      //   },
      // },
      // {
      //   path: "/newDetail",
      //   name: "NewDetail",
      //   component: () => import(/* webpackChunkName: "newDetail" */ "@/views/home/components/news/newsDetail.vue"),
      //   meta: {
      //     title: " 新闻详情 ",
      //   },
      // },
      // {
      //   path: "/newsList",
      //   name: "NewsList",
      //   component: () => import(/* webpackChunkName: "NewsList" */ "@/views/home/components/news/newsList.vue"),
      //   meta: {
      //     title: " 新闻列表 ",
      //   },
      // },
    ],
  },
  // {
  //   path: "/login",
  //   component: () => import(/* webpackChunkName: "login" */ "@/views/login"),
  //   name: "login",
  //   meta: {
  //     title: "login ",
  //   },
  // },
  // {
  //   path: "/register",
  //   component: () => import(/* webpackChunkName: "register" */ "@/views/register"),
  //   name: "register",
  //   meta: {
  //     title: "register ",
  //   },
  // },
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
  // },
];

const routesMobile = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "@/mobile/index.vue"),
    name: "mobile",
    meta: {
      title: "mobile ",
    },
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
        meta: {
          title: " 首页 ",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  // routes: screenW >= 768 ? routes : routesMobile,
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
